import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import "./App.css";
import LoaderScreen from "./components/LoaderScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTE_TERMS_CONDITIONS } from "./constants";
import { Helmet } from "react-helmet";

const Home = lazy(() => import("./pages/home"));
const Contact = lazy(() => import("./pages/home/contact"));
const AboutUs = lazy(() => import("./pages/home/aboutUs"));
const Room = lazy(() => import("./pages/room/Room"));
const TermsConditions = lazy(() =>
  import("./components/terms-conditions/TermsConditions")
);
const TouristsAttractions = lazy(() =>
  import("./pages/tourists-attractions/TouristsAttractions")
);
function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
      />
      <Helmet>
        <title>Hotel Ananda Kashi - Best Hotel in Varanasi</title>
        <link rel="canonical" href="https://hotelanandakashi.com/" />
        <meta
          name="description"
          content="Discover the epitome of Luxury and Comfort at Hotel Ananda Kashi, the Best Hotel in Varanasi. Book your stay now and experience unmatched hospitality. Call us at +91-8957741152 to reserve your Room today!"
        />
      </Helmet>
      <Suspense fallback={<LoaderScreen />}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/room/:category" element={<Room />} />
          <Route
            exact
            path="/tourists_attractions"
            element={<TouristsAttractions />}
          />
          <Route
            exact
            path={ROUTE_TERMS_CONDITIONS}
            element={<TermsConditions />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
